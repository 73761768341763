<template>
  <div class="comment-form">
    <p class="font-weight-bold m-fs-14">Say something about this Developer</p>
    <b-form ref="commentForm" class="comment-form_form d-flex justify-content-between align-items-center py-3">
      <b-form-group
        class="m-fs-14 flex-grow-1"
      >
        <b-form-textarea
          id="comment"
          placeholder="Type Here"
          rows="2"
          class="comment-form_input-field p-4"
        >
        </b-form-textarea>
      </b-form-group>
      <b-button block type="submit"><SendMessageIcon /></b-button>
    </b-form>
    <p class="m-fs-10">( Please note that your comment is confidential & can only be seen by Menaget admin )</p>
  </div>
</template>
<script>
import SendMessageIcon from '../svg/SendMessageIcon.vue';
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SendMessageIcon,
  },
};
</script>
<style lang="scss" scoped>
.comment-form {
  &_form  {
    font-size: 14px;
    color: #afb9c6;
    min-height: 80px;
    border: 1px solid rgba(216, 222, 227, 1);
    border-radius: 5px;
  }

  &_input-field {
    border: none;
    height: 100%;
    border-right: 1px solid rgba(216, 222, 227, 1);
    &:focus {
      box-shadow: none;
    }
  }
  .btn {
    width: 20%;
    background: none;
    height: 100%;
  }
}
</style>
