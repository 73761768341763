<template>
  <div class="chat">
    <div class="d-flex justify-content-between">
      <div>
        <img
          v-if="message.src"
          class="chat__avatar mr-3"
          :src="message.src"
          alt="man-avatar"
        />
        <TextAvatar
          v-if="!message.src"
          :initials="message.name.substr(0, 1).toUpperCase()"
          class="mr-3"
        />
      </div>
      <div class="chat__chat-message m-fs-14">
        <span class="chat__message">{{ message.message }}</span>
      </div>
    </div>
    <div class="text-right">
      <b-button variant="link" class="m-fs-10 dark-blue"> Read more </b-button>
    </div>
  </div>
</template>
<script>
import TextAvatar from "@/components/TextAvatar.vue";
export default {
  components: {
    TextAvatar,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.chat {
  &__avatar {
    width: 30px;
    height: 30px;
  }
}
</style>
