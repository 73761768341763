<template>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.78.22a.75.75 0 0 1 .166.81l-8.729 21.82a1.125 1.125 0 0 1-1.993.186l-4.767-7.493-7.493-4.767a1.125 1.125 0 0 1 .186-1.995L22.97.056a.75.75 0 0 1 .81.165V.219ZM9.955 15.104l4.141 6.507 7.1-17.748L9.954 15.105Zm10.18-12.301-17.748 7.1 6.509 4.14 11.24-11.24Z"
      fill="#556D9E"
    />
  </svg>
</template>
<script>
    export default {
      name: "SendMessageIcon",
    };
</script>