<template>
  <div class="profile-summary">
    <div class="profile-summary__profile d-block d-sm-flex justify-content-between">
      <div class="profile-summary__info">
        <img
          src="../../assets/img/woman-avatar.png"
          class="img-fluid rounded-circle"
          alt="avatar"
        />
        <p class="py-2 px-2 px-sm-0 m-fs-14">
          <span class="d-block mb-1">
            {{ employee.name }}
          </span>
          <span class="d-block">
            {{ employee.primary_skill }}
          </span>
        </p>
      </div>
      <div class="profile-summary__skills mb-4 mb-sm-0 p-2 p-sm-0">
        <SkillCardSlide :skills="skills" />
      </div>
    </div>
    <router-link class="mb-3 d-block text-decoration-none m-fs-12" to="/dashboard/employees">
      <ArrowLeftIcon class="mr-2" /> Back
    </router-link>
  </div>
</template>
<script>
import SkillCardSlide from "./SkillCardSlide.vue";
import ArrowLeftIcon from "../../components/svg/ArrowLeftIcon.vue";

export default {
  components: {
    SkillCardSlide,
    ArrowLeftIcon,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    skills: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.profile-summary {
  &__profile {
    @include media-breakpoint-down(xs) {
      background-color: $white;
      border-radius: 0 0 $size-10 $size-10;
    }  
  }
  &__info {
    @include media-breakpoint-down(xs) {
    padding: 10px;
    }
  }
  &__skills {
    width: 60%;
    @include media-breakpoint-down(xs) {
      width: 80%;
    }
  }
}
</style>
