<template>
  <span
    class="text-avatar d-flex justify-content-center align-items-center m-fs-16 text-primary rounded-circle"
  >
    {{ initials }}
  </span>
</template>
<script>
export default {
  name: "TextAvatar",
  props: {
    initials: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.text-avatar {
  background: rgba(53, 84, 209, 0.08);
  width: 30px;
  height: 30px;
  font-weight: 700;
}
</style>
