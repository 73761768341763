<template>
  <div class="card slide-wrapper">
    <div class="skill-card-slide d-flex justify-content-between align-items-center">
      <button
        class="backward-arrow arrow d-flex align-items-center justify-content-center"
        @click="showPrev"
      >
        <CaretLeftIcon />
      </button>
      <div class="skill-cards">
        <VueSlickCarousel ref="carousel" v-bind="settings" class="d-flex flex-wrap">
          <SkillCard
            v-for="(skill, i) in skills"
            :key="i"
            :skill="skill"
            :small="true"
          />
        </VueSlickCarousel>
      </div>
      <button
        class="forward-arrow arrow d-flex align-items-center justify-content-center"
        @click="showNext"
      >
        <CaretRightIcon />
      </button>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import SkillCard from "@/components/skills/SkillCard";
import CaretRightIcon from "@/components/svg/CaretRightIcon";
import CaretLeftIcon from "@/components/svg/CaretLeftIcon";

export default {
  name: "SkillCardSlide",
  components: {
    VueSlickCarousel,
    SkillCard,
    CaretRightIcon,
    CaretLeftIcon,
  },
  props: {
    skills: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
            },
          },
          {
            breakpoint: 1220,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
            },
          },
          {
            breakpoint: 432,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },

  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-wrapper {
  height: 8.5em;
  border: none;
  background: none;
}

.skill-card-slide {
  height: 100%;

  .skill-cards {
    width: 100%;

    @include media-breakpoint-down(sm) {
      margin-left: 1rem;
    }
  }

  .arrow {
    width: 30px;
    height: 30px;
    border: none;
    font-size: 1.5rem;
    background: none;
    color: $primary;
  }
}
</style>
