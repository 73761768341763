<template>
  <div class="hiring-info m-rounded-12">
    <div class="d-flex justify-content-between">
      <h2>Hiring Information</h2>
      <OptionsDropdown :toggle-class="['text-decoration-none']" :options="options" />
    </div>
    <hr />
    <div>
      <b-row class="mb-4">
        <b-col sm="12" md="5">
          <strong>Project Hired for</strong>
        </b-col>
        <b-col>
          <!-- {{ user.project }} -->
          <div v-for="(project, index) in user.projects_hired_for" :key="index">
            {{ user.projects_hired_for[index] }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="5">
          <strong>Date Hired</strong>
        </b-col>
        <b-col>
          {{ user.date_hired ? user.date_hired : '-' | capitalize }}
          <!-- {{ user.date_hired | dayjs('YYYY-MM-DD') }} -->
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="5">
          <strong>Contract end date</strong>
        </b-col>
        <b-col>
          {{ user.contract_end_date ? user.contract_end_date : '-' | capitalize }}
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="5">
          <strong>Talent Payment</strong>
        </b-col>
        <b-col>
          {{ user.talent_payment ? user.talent_payment : '-' | capitalize }}
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="5">
          <strong>Status</strong>
        </b-col>
        <b-col>
          {{ user.availability_readable ? user.availability_readable : '-' | capitalize }}
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="5">
          <strong>Extension Date</strong>
        </b-col>
        <b-col>
          {{ user.extension_date ? user.extension_date : '-' | capitalize }}
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="5">
          <strong>Reason</strong>
        </b-col>
        <b-col>
          {{ user.reason ? user.reason : '-' }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import OptionsDropdown from "../../components/OptionsDropdown.vue"

export default {
  components: {
    // EllipsisIcon,
    OptionsDropdown
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      default: () => []
    }
  },
};
</script>
<style lang="scss" scoped>
.hiring-info {
  background-color: $body-bg;
  width: 50%;
  max-height: 670px;
  overflow-y: auto;
  h2 {
    color: $black-tint-3;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 600;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
</style>
