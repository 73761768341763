<template>
  <div class="chat-box m-rounded-12">
    <div class="chat-box__chats mb-3">
      <Chat v-for="(chat, i) in chats" :key="i" :message="chat" class="mb-4" />
    </div>
    <div class="message-field">
      <MessageField />
    </div>
  </div>
</template>
<script>
import Chat from "./Chat.vue";
import MessageField from "./MessageField.vue";

export default {
  components: {
    Chat,
    MessageField,
  },
  props: {
    chats: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-box {
  background-color: $body-bg;
  width: 50%;
  max-height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__chats {
    height: 80%;
    overflow: auto;
  }
  &__message-field {
    height: 20%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
</style>
