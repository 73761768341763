<template>
  <div class="px-4 py-3 dashboard-summary">
    <ProfileSummary :employee="employee" :skills="skills" />
    <div class="dashboard-summary__feedback-details d-flex justify-content-between mb-5">
      <HiringInfo :user="employee" class="p-3 mr-3" :options="options" />
      <FeedbackChatBox class="dashboard-summary__chat-box p-3 ml-3" :chats="chats" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ProfileSummary from "../../components/dashboard/ProfileSummary.vue";
import HiringInfo from "../../components/employee/HiringInfo.vue";
import FeedbackChatBox from "../../components/dashboard/FeedbackChatBox.vue";

export default {
  components: {
    ProfileSummary,
    HiringInfo,
    FeedbackChatBox,
  },
  data() {
    return {
      user: {
        name: "Amarachi Gloria Chinedu-Okorochi",
        role: "Backend Developer",
        project:
          "Srspiciatis undeatus Srspiciatis undeatus Srspiciatis undeatus",
        dateHired: "21/06/21",
        contractEnd: "21/06/21",
        salary: "$2,000",
        status: "Contract Extended",
        extensionDate: "21/06/21",
        reason:
          "Srspiciatis undeatus Srspiciatis undeatus Srspiciatis undeatus",
        // placeHolder: "Type Here",
      },
      skills: [
        {
          name: 'React',
          level: 'Senior'
        },
        {
          name: 'Vue',
          level: 'Intermediate'
        },
        {
          name: 'Node.js',
          level: 'Junior'
        },
        {
          name: 'Vue',
          level: 'Intermediate'
        },
       
      ],
      chats: [
        {
          id: 1,
          message:
            "Srspiciatis undeatus Srspiciatis undeatus Srspiciatis undeatus  Srspiciatis undeatus ",
            name: "Bolaji"
        },
        {
          id: 2,
          message:
            "Srspiciatis undeatus Srspiciatis undeatus Srspiciatis undeatus  Srspiciatis undeatus",
            name: "Abraham"
        },
        {
          id: 3,
          message:
            "Srspiciatis undeatus Srspiciatis undeatus Srspiciatis undeatus  Srspiciatis undeatus",
            name: "Monsur"
        },
      ],
      options: [
        {
          title: "End Contract",
          route: "/#",
          class: ["text-secondary"],
        },
        {
          title: "Extend Contract",
          route: "/#",
          class: ["text-success"],
        },
      ],

    };
  },
  computed: {
    ...mapState({
      employee: (state) => state.employees.employee
    })
  },
  created() {
    this.fetchEmployee(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      fetchEmployee: "employees/fetchEmployee"
    })
  }
};
</script>
<style lang="scss" scoped>
.dashboard-summary {
  &__feedback-details {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__chat-box {
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }
}
</style>
